import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "react-bootstrap";

/**
 *	Stranka s prihlasovacim tlacitkem do SSO
 */
export function Login() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("app.pageTitle") + " - " + t("sso.header");
  });

  return (
    <Row className="justify-content-md-center">
      <Col xs="12" md="11" lg="10" xl="9" className="playground">
        <h3>{t("sso.text.header")}</h3>
        <p>{t("sso.text.description")}</p>
        <div className="text-center" style={{ marginTop: "8em" }}>
          <a href={process.env.PUBLIC_URL + "/sso"}>
            <Button color="primary">{t("sso.button.login")}</Button>
          </a>
        </div>
      </Col>
    </Row>
  );
}
