import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import IdentitaObcanaLogo from "../../images/indentita-obcana-logo-300.png";
import { Button, Col, Row } from "react-bootstrap";

/**
 *	Vitejte
 */
export function Welcome() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = t("app.pageTitle") + " - " + t("welcome.header");
  });

  return (
    <Row className="justify-content-md-center">
      <Col xs="12" md="11" lg="10" xl="9" className="playground">
        <h3>{t("welcome.text.header")}</h3>
        <p>{t("welcome.text.announcement")}</p>
        <div className="text-center">
          <a href="https://www.identitaobcana.cz">
            <img src={IdentitaObcanaLogo} alt="identita obcana" />
          </a>
          <div>
            <a href={process.env.PUBLIC_URL + "/nia"}>
              <Button color="primary">{t("welcome.button.login")}</Button>
            </a>
          </div>
        </div>
      </Col>
    </Row>
  );
}
