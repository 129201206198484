import React from "react";
import "./App.scss";
import { Container } from "react-bootstrap";
import { Header } from "./features/header";
import { AlertMessage } from "./features/message";
import { Route, Routes } from "react-router-dom";
import { Welcome } from "./features/welcome";
import { Request } from "./features/request";
import { useGetRestAuthUserProfileQuery } from "./api/fundsRequest";
import { Required } from "./features/nia";
import { Login } from "./features/sso";

function App() {
  // nacteni profilu
  const { data: userProfile, isFetching } = useGetRestAuthUserProfileQuery();

  // test zda jsou vyplneny vsechny povinne atributy
  const missProfileParam =
    !isFetching &&
    userProfile &&
    (!userProfile.currentGivenName || !userProfile.currentFamilyName || !userProfile.dateOfBirth);

  return (
    <div className="App">
      <div id="bg"></div>
      <Header />
      <Container fluid id="content">
        <AlertMessage />

        {isFetching || (!userProfile && <Welcome />)}
        {!isFetching && userProfile && missProfileParam && <Required />}
        {!isFetching && userProfile && !missProfileParam && !userProfile.login && <Login />}
        {!isFetching && userProfile && !missProfileParam && userProfile.login && (
          <Routes>
            <Route path="/" element={<Request />} />
          </Routes>
        )}
      </Container>
    </div>
  );
}

export default App;
