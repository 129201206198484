import { Toast, ToastContainer } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { hide } from "./messageSlice";

/**
 * notifikacni zprava
 */
export function AlertMessage() {
  // funkce pro preklady
  const { t } = useTranslation();

  const message = useAppSelector((state) => state.message);
  const dispatch = useAppDispatch();

  return (
    <ToastContainer containerPosition="fixed" className="p-3" position="top-end">
      {message.show && (
        <Toast
          onClose={() => dispatch(hide())}
          show={message.show}
          delay={5000}
          autohide
          bg={message.error ? "danger" : "info"}
        >
          <Toast.Header>
            <strong className="me-auto">{message.error ? t("error.header") : t("info.header")}</strong>
          </Toast.Header>
          <Toast.Body>{message.text}</Toast.Body>
        </Toast>
      )}
    </ToastContainer>
  );
}
