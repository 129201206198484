import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Col, Form, Row } from "react-bootstrap";
import { FundsRequest, useGetRestAuthUserProfileQuery, usePostRestFundsRequestMutation } from "../../api/fundsRequest";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import UI from "../ui";
import { useAppDispatch } from "../../app/hooks";
import { showInfo } from "../message";
import { NoticeModal } from "./NoticeModal";

/**
 *	Vitejte
 */
export function Request() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [noticeModalShow, setNoticeModalShow] = React.useState(false);

  // nacteni profilu
  const { data: userProfile } = useGetRestAuthUserProfileQuery();

  // schema validace
  const schema: yup.ObjectSchema<FundsRequest> = yup
    .object({
      bsi: yup.string().required(),
      firstName: yup.string().trim().required(),
      lastName: yup.string().trim().required(),
      email: yup.string().trim().email().required(),
      dateOfBirth: yup.string().trim().required(),
      professionalQualification: yup.string().trim().required(),
      academicQualification: yup.string().trim().required(),
      motivation: yup.string().trim().required(),
      confirm: yup.boolean().required().oneOf([true], t("field.mixed.required")),
    })
    .required();

  // validace formulare
  const { control, handleSubmit, reset } = useForm<FundsRequest>({
    defaultValues: {
      bsi: userProfile!.personIdentifier,
      firstName: userProfile!.currentGivenName,
      lastName: userProfile!.currentFamilyName,
      email: userProfile!.email,
      dateOfBirth: userProfile!.dateOfBirth,
      professionalQualification: "",
      academicQualification: "",
      motivation: "",
      confirm: false,
    },
    resolver: yupResolver(schema, { stripUnknown: true }),
  });

  // akce odeslani zadosti
  const [postRestFundsRequest, postRestFundsRequestResult] = usePostRestFundsRequestMutation();

  useEffect(() => {
    document.title = t("app.pageTitle") + " - " + t("request.header");
  });

  // ulozeni formulare
  const onSubmit = (data: FundsRequest) => {
    postRestFundsRequest({ fundsRequest: data });
  };

  // akce po ulozeni dat
  useEffect(() => {
    if (postRestFundsRequestResult.isSuccess) {
      dispatch(showInfo(t("request.info.sent")));
      reset();
      setNoticeModalShow(true);
    }
  }, [dispatch, t, postRestFundsRequestResult, reset]);

  const dateOfBirth = t("format.date", {
    value: new Date(userProfile!.dateOfBirth),
    formatParams: {
      value: { dateStyle: "long" },
    },
  });
  return (
    <Row className="justify-content-md-center">
      <Col xs="12" md="11" lg="10" xl="9" className="playground">
        <h3>{t("request.text.header")}</h3>

        <NoticeModal show={noticeModalShow} onHide={() => setNoticeModalShow(false)} />

        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <UI.Input
            control={control}
            name="firstName"
            label={t("request.label.firstName")}
            required={true}
            readOnly={true}
            disabled={true}
          />
          <UI.Input
            control={control}
            name="lastName"
            label={t("request.label.lastName")}
            required={true}
            readOnly={true}
            disabled={true}
          />
          <Form.Group className={"mb-3 required"}>
            <Form.Label>{t("request.label.dateOfBirth")}</Form.Label>
            <Form.Control defaultValue={dateOfBirth} readOnly={true} disabled={true} />
          </Form.Group>
          <UI.Input control={control} name="email" label={t("request.label.email")} required={true} />
          <UI.Input
            control={control}
            name="professionalQualification"
            label={t("request.label.professionalQualification")}
            required={true}
            rows={5}
          />
          <UI.Input
            control={control}
            name="academicQualification"
            label={t("request.label.academicQualification")}
            required={true}
            rows={5}
          />
          <UI.Input
            control={control}
            name="motivation"
            label={t("request.label.motivation")}
            required={true}
            rows={5}
          />
          <UI.Check control={control} name="confirm" label={t("request.text.confirm")} required={true} />
          <UI.ActionButton
            type="submit"
            isLoading={postRestFundsRequestResult.isLoading}
            label={t("request.button.send")}
          />
        </Form>
      </Col>
    </Row>
  );
}
