import { Form } from "react-bootstrap";
import { FieldValues, useController, UseControllerProps } from "react-hook-form";
import { xor } from "lodash";

interface Props<Type extends FieldValues> extends UseControllerProps<Type> {
  label?: string;
  className?: string;
  required?: boolean;
  value?: any;
  id?: string;
  inline?: boolean;
}

/**
 *	Vstupni formularove policko
 */
export function Check<Type extends FieldValues>({ control, name, className = "mb-3", ...props }: Props<Type>) {
  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const CheckComponent = (
    <Form.Check
      id={props.id ? props.id : name}
      label={props.label}
      inline={props.inline}
      type="checkbox"
      checked={Array.isArray(field.value) ? field.value?.includes(props.value) : field.value}
      isInvalid={error !== undefined}
      feedbackType="invalid"
      feedback={error?.message}
      onChange={
        Array.isArray(field.value)
          ? () => field.onChange(xor(field.value, [props.value]))
          : (e) => field.onChange(e.target.checked)
      }
    />
  );

  if (props.inline) return CheckComponent;

  return (
    <Form.Group className={className + (props.required ? " required" : "")} controlId={field.name}>
      {CheckComponent}
    </Form.Group>
  );
}
