import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import { messageSlice, rtkQueryErrorLogger } from "../features/message";
import { fundsRequestApi } from "../api/fundsRequest";

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [fundsRequestApi.reducerPath]: fundsRequestApi.reducer,
    message: messageSlice.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(fundsRequestApi.middleware).concat(rtkQueryErrorLogger),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
