import * as yup from "yup";

export default function buildYupLocale(_err: any, t: Function) {
  yup.setLocale({
    mixed: {
      default: t("field.mixed.default"),
      required: t("field.mixed.required"),
      oneOf: ({ values }) => t("field.mixed.oneOf", { values: values }),
      notOneOf: ({ values }) => t("field.mixed.notOneOf", { values: values }),
    },
    string: {
      length: ({ length }) => t("field.string.length", { value: length }),
      min: ({ min }) => t("field.string.min", { value: min }),
      max: ({ max }) => t("field.string.max", { value: max }),
      matches: ({ regex }) => t("field.string.min", { value: regex }),
      email: t("field.string.email"),
      url: t("field.string.url"),
      trim: t("field.string.trim"),
      lowercase: t("field.string.lowercase"),
      uppercase: t("field.string.uppercase"),
    },
    number: {
      min: ({ min }) => t("field.number.min", { value: min }),
      max: ({ max }) => t("field.number.max", { value: max }),
      lessThan: ({ less }) => t("field.number.lessThan", { value: less }),
      moreThan: ({ more }) => t("field.number.moreThan", { value: more }),
      positive: t("field.number.positive"),
      negative: t("field.number.negative"),
      integer: t("field.number.integer"),
    },
    date: {
      min: ({ min }) => t("field.date.min", { value: min }),
      max: ({ max }) => t("field.date.max", { value: max }),
    },
  });
}
