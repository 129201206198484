export function setCookie(cname: string, cvalue: string, exmins: number, domain = null) {
  var expires = "";
  if (exmins) {
    var d = new Date();
    d.setTime(d.getTime() + exmins * 60 * 1000);
    expires = ";expires=" + d.toUTCString();
  }
  document.cookie = cname + "=" + cvalue + expires + ";path=/" + (domain ? ";domain=" + domain : "");
}

export function getCookie(cname: string): string | undefined {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return undefined;
}

export function eraseCookie(name: string) {
  setCookie(name, "", -1);
}

export function eraseCookiesStartsWithPrefixes(prefixes: string[]) {
  var cookieNames = document.cookie.split(/=[^;]*(?:;\s*|$)/);
  for (let i = 0; i < cookieNames.length; i++) {
    prefixes.forEach((prefix) => {
      if (cookieNames[i].startsWith(prefix)) {
        eraseCookie(cookieNames[i]);
      }
    });
  }
}
