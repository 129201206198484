import { Button, Nav, Navbar } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { UIEvent } from "react";
import LogoVSBcs from "../../images/vsb_cs.svg";
import LogoVSBen from "../../images/vsb_en.svg";
import { useGetRestAuthUserProfileQuery } from "../../api/fundsRequest";

const UniversityLogo = new Map<string, string>([
  ["cs", LogoVSBcs],
  ["en", LogoVSBen],
]);

/**
 *    Hlavicka
 */
export function Header() {
  // preklady
  const { t } = useTranslation();

  // nacteni profilu
  const { data: userProfile, isFetching } = useGetRestAuthUserProfileQuery();

  const handleLanguage = (event: UIEvent, lang: string) => {
    document.cookie = "language=" + lang + "; path=/";
    event.preventDefault();
    window.location.reload();
  };

  return (
    <header>
      <Navbar id="navbar" expand="md">
        <Navbar.Brand href={process.env.PUBLIC_URL}>
          <a href="https://www.vsb.cz">
            <img className="logo" alt="logo" src={UniversityLogo.get(i18n.language)} />
          </a>
          <span className="brand d-none d-sm-inline">{t("header.text.main")}</span>
          <span className="brand d-inline d-sm-none">{t("header.text.mainShort")}</span>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse id="basic-navbar-nav" className="flex-md-column">
          <Nav id="menu-profile" className="ms-auto">
            {!isFetching && userProfile && (
              <span className="nav-link">
                {userProfile.currentGivenName} {userProfile.currentFamilyName}
              </span>
            )}
            {!isFetching && userProfile && (
              <a className="nav-link" href={process.env.PUBLIC_URL + "/niaLogout"}>
                {t("header.link.logout")}
              </a>
            )}
          </Nav>
          <Nav id="main-menu" className="ms-auto">
            <Nav.Link as="div" className="ms-4 fw-normal">
              <Button variant="link" onClick={(e) => handleLanguage(e, "cs")} title={t("title.czech")}>
                {i18n.language === "cs" ? <strong>CZ</strong> : <span>CZ</span>}
              </Button>
              <Button
                variant="link"
                className="ms-3"
                onClick={(e) => handleLanguage(e, "en")}
                title={t("title.english")}
              >
                {i18n.language === "en" ? <strong>EN</strong> : <span>EN</span>}
              </Button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
}
