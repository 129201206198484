import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "react-bootstrap";
import { useGetRestAuthUserProfileQuery } from "../../api/fundsRequest";

/**
 *	Stranka ohlasujici povinne atributy z NIA
 */
export function Required() {
  const { t } = useTranslation();

  // nacteni profilu
  const { data: userProfile } = useGetRestAuthUserProfileQuery();

  useEffect(() => {
    document.title = t("app.pageTitle") + " - " + t("nia.header.required");
  });

  return (
    <Row className="justify-content-md-center">
      <Col xs="12" md="11" lg="10" xl="9" className="playground">
        <h3>{t("nia.text.header.required")}</h3>
        <ul>
          <li>
            {t("nia.text.firstName")}
            {!userProfile?.currentGivenName && <span className="text-danger"> {t("nia.text.notGranted")}</span>}
          </li>
          <li>
            {t("nia.text.lastName")}
            {!userProfile?.currentFamilyName && <span className="text-danger"> {t("nia.text.notGranted")}</span>}
          </li>
          <li>
            {t("nia.text.dateOfBirth")}
            {!userProfile?.dateOfBirth && <span className="text-danger"> {t("nia.text.notGranted")}</span>}
          </li>
        </ul>
        <div className="text-center">
          <a href={process.env.PUBLIC_URL + "/niaLogout"}>
            <Button color="primary">{t("nia.button.logout")}</Button>
          </a>
        </div>
      </Col>
    </Row>
  );
}
