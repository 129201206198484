import { Input } from "./Input";
import { Check } from "./Check";
import { ActionButton } from "./ActionButton";

export * from "./Input";
export * from "./Check";
export * from "./ActionButton";

export default Object.assign(
  {},
  {
    Input: Input,
    Check: Check,
    ActionButton: ActionButton,
  }
);
