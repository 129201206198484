import { Button, Modal, ModalProps } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export function NoticeModal(props: ModalProps) {
  const { t } = useTranslation();

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">{t("noticeModal.header")}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{t("noticeModal.text.notice")}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>{t("button.close")}</Button>
      </Modal.Footer>
    </Modal>
  );
}
