import { emptySplitApi as api } from "./empty";
export const addTagTypes = [] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getRestAuthUserProfile: build.query<GetRestAuthUserProfileApiResponse, GetRestAuthUserProfileApiArg>({
        query: () => ({ url: `/rest/auth/userProfile` }),
      }),
      postRestFundsRequest: build.mutation<PostRestFundsRequestApiResponse, PostRestFundsRequestApiArg>({
        query: (queryArg) => ({ url: `/rest/funds/request`, method: "POST", body: queryArg.fundsRequest }),
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as fundsRequestApi };
export type GetRestAuthUserProfileApiResponse = /** status 200 OK */ UserProfile;
export type GetRestAuthUserProfileApiArg = void;
export type PostRestFundsRequestApiResponse = unknown;
export type PostRestFundsRequestApiArg = {
  fundsRequest: FundsRequest;
};
export type UserProfile = {
  personIdentifier: string;
  email?: string;
  currentGivenName: string;
  currentFamilyName: string;
  dateOfBirth: string;
  login?: string;
};
export type FundsRequest = {
  bsi: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  email: string;
  professionalQualification: string;
  academicQualification: string;
  motivation: string;
  confirm: boolean;
};
export const { useGetRestAuthUserProfileQuery, usePostRestFundsRequestMutation } = injectedRtkApi;
