import { MouseEventHandler } from "react";
import { Button, Spinner } from "react-bootstrap";

interface Props {
  label: string;
  isLoading?: boolean;
  variant?: string;
  className?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: "reset" | "button" | "submit" | undefined;
}

/**
 *	Potvrzovaci tlacitko formulare
 */
export function ActionButton({ isLoading = false, ...props }: Props) {
  return (
    <Button
      variant={props.variant}
      type={props.type}
      disabled={isLoading}
      className={props.className}
      onClick={props.onClick}
    >
      {isLoading && <Spinner className="me-2" as="span" animation="border" size="sm" />}
      {props.label}
    </Button>
  );
}
