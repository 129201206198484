import { showError } from "./messageSlice";
import { isRejectedWithValue, Middleware } from "@reduxjs/toolkit";
import i18next from "../../i18n";

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware = (api) => (next) => (action) => {
  // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
  if (isRejectedWithValue(action)) {
    if (action.payload.status >= 400 && action.payload.status < 500) {
      api.dispatch(showError(action.payload.data));
    } else {
      api.dispatch(showError(i18next.t("error.connectionFailed")));
    }
  }
  return next(action);
};
