import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import messagesCS from "./messages/cs.json";
import messagesEN from "./messages/en.json";
import buildYupLocale from "./buildYupLocale";
import { getCookie } from "./utils/cookies";

let lang = getCookie("language");

if (!lang) {
  lang = "cs";

  let prefixLang = navigator.language ? navigator.language.toLowerCase().substring(0, 2) : "cs";

  if (prefixLang !== "cs" && prefixLang !== "sk") {
    // nez bude preklad
    lang = "en";
  }
}

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: { ...messagesEN },
  },
  cs: {
    translation: { ...messagesCS },
  },
  sk: {
    translation: { ...messagesCS },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(
    {
      resources,
      lng: lang, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
      // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
      // if you're using a language detector, do not define the lng option

      interpolation: {
        escapeValue: false, // react already safes from xss
      },
    },
    buildYupLocale
  );

export default i18n;
